<script>
  import { createEventDispatcher } from "svelte";
  import VideoItem from "./VideoItem.svelte";
  import VideoFilter from "./VideoFilter.svelte";
  import Button from "../UI/Button.svelte";

  export let meetups;

  const dispatch = createEventDispatcher();

  let favsOnly = false;

  $: filteredMeetups = favsOnly ? meetups.filter(m => m.lang==="en") : meetups;

  function setFilter(event) {
    favsOnly = event.detail === 1;
  }
</script>

<style>
  #meetups {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  #meetup-controls {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 720px) {
    #meetups {
      grid-template-columns: repeat(4, 1fr);
    }
     
  }
</style>

<section id="meetup-controls">
  <VideoFilter on:select={setFilter} />
</section>
<section id="meetups">
  {#each filteredMeetups as meetup (meetup.id)}
  
    <VideoItem
      id={meetup.id}
      title={meetup.title}
      
      description={meetup.description}
      lang={meetup.lang}
      imageUrl={meetup.imageUrl}
      
      isFav={meetup.isFavorite}
      on:showdetails
      on:edit />
  {/each}
</section>
