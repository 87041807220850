<script>
  import meetups from "./Videos/videos-store.js";
  import Header from "./UI/Header.svelte";
  import VideoGrid from "./Videos/VideoGrid.svelte";
  import Pager from "./Videos/Pager.svelte";
  
  import Button from "./UI/Button.svelte";

  import VideoDetail from "./Videos/VideoDetail.svelte";
  import LoadingSpinner from "./UI/LoadingSpinner.svelte";
  import Error from "./UI/Error.svelte";

  // let meetups = ;

  
  
  let page = "overview";
  let pageData = {};
  let isLoading = true;
  let error;

  
  //fetch("https://videos.comovid.com/branovate/branovate.mrss")
  fetch("https://videos.comovid.com/google_news/google_news.mrss")
  //fetch("https://videos.comovid.com/comovid/comovid.mrss")
  //fetch("http://localhost:5000/branovate.mrss")
    .then(res => {
      if (!res.ok) {
        throw new Error("Fetching MRSS failed, please try again later!");
      }
      return res.text();
    })
    .then(data => {
      let parser =new DOMParser();
      let xmlDoc=parser.parseFromString(data,'text/xml');
      let items=xmlDoc.getElementsByTagName('item');
      return items;

    })
    .then(data => {
      
      const loadedMeetups = {'items':[],'pager':{}};
      const itemsPerPage=12;
      let pageMeetups=[];
      for (let i=0; i<data.length;i++)
      {
        let title=data[i].getElementsByTagName('title')[0].textContent;
        let id=data[i].getElementsByTagName('comovid:publisher_id')[0].textContent+"_"+Math.random().toString(36).substr(2, 9);
        let description=data[i].getElementsByTagName('comovid:timestamp')[0].textContent;
        let videoDT=description.split("T")[0].split('-').reverse().join("/") +' ' +description.split("T")[1].replace(/-/gi,":").replace("Z","")
        description=videoDT

        let video=data[i].getElementsByTagName('media:content')[0].attributes['url'].nodeValue;
        let lang=data[i].getElementsByTagName('media:content')[0].attributes['lang'].nodeValue;
        let imageUrl=data[i].getElementsByTagName('media:thumbnail')[0].attributes['url'].nodeValue;
        let linksUrl=data[i].getElementsByTagName('media:comment')[0].textContent;
        let isFavorite=false;

        let meet={id,description,video,imageUrl,isFavorite, title,linksUrl,lang};
        pageMeetups.push(meet);
        if ((i+1) % itemsPerPage===0)
        {
          loadedMeetups['items'].push(pageMeetups);
          pageMeetups=[];
        }

        
      }
      if (pageMeetups !== null)
      {
        loadedMeetups['items'].push(pageMeetups);
      }
      loadedMeetups['pager']['total']=loadedMeetups['items'].length;
      loadedMeetups['pager']['current_page']=0;
      loadedMeetups['pager']['last_page']=loadedMeetups['items'].length-1;
      console.log(loadedMeetups['pager']['total'])
      return loadedMeetups
    })

    .then(data => {
      const loadedMeetups = data;
       meetups.setMeetups(loadedMeetups);
       
      isLoading = false;
     
    })
    .catch(err => {
      error = err;
      isLoading = false;
      console.log(err);
    });

  
  function changePage(event)
  {
    console.log('log');
    console.log(event.detail);
    meetups.setCurrentPage(event.detail);

  }

  function showDetails(event) {
    page = "details";
    pageData.id = event.detail;
  }

  function closeDetails() {
    page = "overview";
    pageData = {};
  }



  function clearError() {
    error = null;
  }
</script>

<style>
  main {
    margin-top: 5rem;
  }
</style>

{#if error}
  <Error message={error.message} on:cancel={clearError} />
{/if}

<Header />

<main>
  {#if page === 'overview'}
 
    {#if isLoading}
      <LoadingSpinner />
    {:else}
      <VideoGrid
        meetups={$meetups['items'][$meetups['pager']['current_page']]}
        on:showdetails={showDetails}
      
         />
         <Pager on:change={changePage} pager={$meetups['pager']}/>
    {/if}
  {:else}
    <VideoDetail   id={pageData.id} on:close={closeDetails} />
  {/if}
  
</main>
