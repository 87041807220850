<script>
  import { createEventDispatcher } from "svelte";
  

  export let pager;
  

  const dispatch = createEventDispatcher();

  function range(size, startAt = 0) {
    
    let pager_span=1;
  
    if (pager_span=>size)
    {
      pager_span=Math.ceil(size/2)+1
    }

    const half_span=Math.floor(pager_span/2)
    
    if (startAt>half_span && startAt<=size-half_span)
    {
      startAt=startAt-half_span;
    }
    if (startAt>size-half_span)
    {
      startAt=size-pager_span;
    }
    return [...Array(pager_span).keys()].map(i => i + startAt);
  }
  function changePage(page) {
    if (page !== pager['current_page']) {
      dispatch('change', page);
    }
  }
</script>

<style>



  .pagination {
    display: flex;
    
    justify-content: center;
  }
  .pagination ul {
    display: flex;
    width: 360px;
    padding-left: 0;
    list-style: none;
  }
  .pagination li a {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    background-color: #fff;
    border: 1px solid #dee2e6;
    color: #cf0056;
  }
  .pagination li.active a {
    color: #fff;
    background-color: #cf0056;
    border-color: #cf0056;
  }
  .pagination li.disabled a {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    border-color: #dee2e6;
  }
</style>

<nav class="pagination">
  <ul>
    <li class="{pager['current_page'] === 0 ? 'disabled' : ''}">
      <a href="javascript:void(0)" on:click="{() => changePage(pager['current_page'] - 1)}">
        <span aria-hidden="true">«</span>
      </a>
    </li>
    {#each range(pager['last_page'], pager['current_page']) as page}
    <li class='{page === pager['current_page'] ? "active": ""}'>
      <a href="javascript:void(0)" on:click="{() => changePage(page)}">{page}</a>
    </li>
    {/each}
    <li class="{pager['current_page'] === pager['last_page'] ? 'disabled' : ''}">
      <a href="javascript:void(0)" on:click="{() => changePage(pager['current_page'] + 1)}">
        <span aria-hidden="true">»</span>
      </a>
    </li>
  </ul>
</nav>
