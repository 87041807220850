<script>
  import { createEventDispatcher } from "svelte";
  import meetups from "./videos-store.js";
  import Button from "../UI/Button.svelte";
  import Badge from "../UI/Badge.svelte";

  export let id;
  export let title;
  export let description;
  export let imageUrl;
  export let lang;
  
  

  export let isFav;

  const dispatch = createEventDispatcher();

  function toggleFavorite() {
    meetups.toggleFavorite(id);
  }
</script>

<style>
  article {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    background: white;
    margin: 1rem;
  }

  header,
  
  footer {
    padding: 1rem;
  }

  footer {
    text-align: center;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  h1 {
    font-size: 0.7rem;
    margin: 0.5rem 0;
    text-align: center;
    font-family: "Roboto Slab", sans-serif;
  }


 
p{
  color: #333;
    font-size: small;
    font-family: "Roboto Slab", sans-serif;
  text-align: center
}

  div {
    text-align: right;
  }

 
  @media (min-width: 768px) {
   h1 {
    font-size: 1.25rem;
    margin: 0.5rem 0;
    font-family: "Roboto Slab", sans-serif;
  }
 
     
  }
</style>

<article>
<div class="image">
    <img src={imageUrl} alt={title} />
  </div>
   <footer>
  
   
    <Button type="button" on:click={() => dispatch('showdetails', id)}>
      Play
    </Button>
  </footer>
  <header>
  <p>{description} <span>({lang})</span></p>
    <h1>
      {title}
      {#if isFav}
        <Badge>FAVORITE</Badge>
      {/if}
    </h1>
    
  </header>
  
  
 
</article>
