<script>
  import { onDestroy, createEventDispatcher } from "svelte";
  import meetups from "./videos-store.js";
  import Button from "../UI/Button.svelte";
  import { slide,fly } from "svelte/transition";
  

  export let id;
  let link='';
  let links=[];
  let linkId=0;
  let error;
    let isLoading = true;
    let playing=false;
  let time = 0
  let selectedMeetup;
   const unsubscribe = meetups.subscribe(items => {
    selectedMeetup = items['items'][items['pager']['current_page']].find(i => i.id === id);
  });

  fetch(selectedMeetup.linksUrl)
    .then(res => {
      if (!res.ok) {
        throw new Error("Fetching meetups failed, please try again later!");
      }
      return res.json();
    })
  
    .then(data => {
       const video_links = data[1];
      
      return video_links
    })

    .then(data => {
      links=data;
       
      isLoading = false;
     
    })
    .catch(err => {
      error = err;
      isLoading = false;
      console.log(err);
    });

  

$: {
  if (!isLoading)
  {
  for (let i=linkId;i<links.length;i++)
  {
    if (time>links[i].startTime && time<=links[i].endTime)
    {
      
      link=links[i].url
      linkId=i;
      playing=true
      break;
    }
    playing=false;
  }
  }
  
};
$: console.log(link);
 

  const dispatch = createEventDispatcher();

  onDestroy(() => {
    unsubscribe();
  });

</script>

<style>
  section {
    margin-top: 4rem;
  }

  .image {
   display: flex;
    justify-content: center;
    align-items: center;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image {
    background: #e7e7e7;
  }

  .content {
    text-align: center;
    width: 80%;
    margin: auto;
  }

  h1 {
    font-size: 1.0rem;
    font-family: "Roboto Slab", sans-serif;
    margin: 0.5rem 0;
  }

  

  p {
    font-size: 1.5rem;
  }
  .wrapper {
  position:relative;  
}

.link_playing {
  position: absolute;
    top: 60px;
    right: 0;
    background: rgba(207, 0, 86,0.65);
    color: #fff;
    border: beige;
    border-radius: 12px;
    width: 150px;
    height: 50px;
    text-decoration: none;
  
    text-align: center;
    padding-top: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.link_not_playing
{
  display: none
}
</style>

<section>
  <div class="image">
<div class="wrapper">
    <video bind:currentTime={time} controls autoplay><source src={selectedMeetup.video} alt={selectedMeetup.title} type="video/mp4"></video>
    {#if playing}
    <a   class="link_playing"  href={link} target="_blank">{"article #"+(linkId+1).toString()}</a>
    {/if}
    </div>
  </div>
  <div class="content">
    <h1>{selectedMeetup.title}</h1>
    <p>{selectedMeetup.description}</p>
    
    <Button type="button" mode="outline" on:click={() => dispatch('close')}>
      Close
    </Button>
  </div>
</section>
